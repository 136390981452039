import {Button, Snackbar, TextField} from "@mui/material";
import {CgCopy} from "react-icons/cg";
import React from "react";

interface URLViewerProps {
  URL: string;
  style: {
    urlFieldWidth: string;
    titleMarginRight: string;
  };
}

export function URLViewer(props: URLViewerProps) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleClick = (includeUrl: boolean) => {
    const textToCopy = includeUrl ? props.URL : props.URL.split('?')[1];
    navigator.clipboard.writeText(textToCopy).then(() => {
      setMessage("URL " + (includeUrl ? "" : "(without Base URL)") +  " is copied to clipboard");
      setOpen(true);
    });
  };

  return (
    <>
      <h2 style={{marginRight: props.style.titleMarginRight}}>URL</h2>
      <TextField
        slotProps={{ input: { disabled: true } }}
        type="text"
        value={props.URL}
        style={{width: props.style.urlFieldWidth, textTransform: "none"}}
      />
      <div style={{ marginTop: "25px" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleClick(true)}
          style={{width: "200px", height: "50px", textTransform: "none", marginLeft: "20px"}}
        >
          <CgCopy
            size="2.5em"
            style={{height: "100px", textTransform: "none", marginRight: "10px"}}
          />
          Copy to Clipboard
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleClick(false)}
          style={{width: "200px", height: "50px", textTransform: "none", marginLeft: "20px"}}
        >
          <CgCopy
            size="4.75em"
            style={{height: "100px", textTransform: "none", marginRight: "10px"}}
          />
          Copy to Clipboard without Base URL
        </Button>
      </div>
      <Snackbar
        message={message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}

