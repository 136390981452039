import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import {Campaign, SortModel} from "../common/types";
import {Backdrop, Button, Grid2, Paper, Theme} from "@mui/material";
import React, {useState} from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {Summary} from "./Summary";
import {URLViewer} from "./URLViewer";

interface DataDisplayProps {
  currentCampaigns: Campaign[];
  loading: boolean;
  theme: Theme;
}

export function DataDisplay(props: DataDisplayProps) {
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>({} as Campaign);
  const [sortModel, setSortModel] = useState<SortModel[]>([
    { colId: 'MarketingChannel', sort: 'asc', sortIndex: 0 },
    { colId: 'createdAt', sort: 'desc', sortIndex: 1 },
  ]);
  const [colDefs] = useState<ColDef<Campaign>[]>([
    { field: "MarketingChannel", headerName: "Channel", filter: true, filterParams: { buttons: ['reset'] } },
    { field: "TargetCountry", headerName: "Country" },
    { field: "TargetMedia", headerName: "Media" },
    { field: "CampaignType", headerName: "Type" },
    { field: "CampaignName", headerName: "Name" },
    { field: "Event" },
    { field: "Partner" },
    { field: "Platform", headerName: "Campaign ID" },
    { field: "UTMMedium", headerName: "UTM Medium" },
    { field: "UTMSource", headerName: "UTM Source" },
    {
      field: "createdAt", headerName: "Created At", comparator:
        (valueA, valueB) => {
          const [date, time] = valueA.split(", ");
          const [day, month, year] = date.split("/");
          const [hour, minute, second] = time.split(":");
          const dateA = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`).getTime();
          const [date2, time2] = valueB.split(", ");
          const [day2, month2, year2] = date2.split("/");
          const [hour2, minute2, second2] = time2.split(":");
          const dateB = new Date(`${year2}-${month2}-${day2}T${hour2}:${minute2}:${second2}`).getTime();
          return dateA - dateB;
        },
      minWidth: 175
    }
  ]);

  const defaultColDef: ColDef = {
    flex: 1,
    resizable: false,
    suppressMovable: true,
  };

  return (
    <>
      <div
        className={
          props.theme.palette.mode === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine"
        }
        style={{width: "100%", height: "100%"}}
      >
        <AgGridReact
          rowData={props.currentCampaigns}
          onRowClicked={(event) => {
            setSummaryOpen(true);
            setSelectedCampaign("urlString" in event.data ? event.data : {...event.data, urlString: ""});
          }}
          onSortChanged={(params) => {
            const newSortModel = params.api.getColumnState()
              .filter((col) => col.sort)
              .map((col) => ({ colId: col.colId, sort: col.sort, sortIndex: col.sortIndex }));
            setSortModel(newSortModel);
          }}
          onGridReady={(event) =>
            event.api.applyColumnState({ state: sortModel, applyOrder: true })
          }
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowHeight={35}
          domLayout='autoHeight'
          pagination={true}
          paginationPageSize={10}
          paginationPageSizeSelector={[10, 25, 50, 100]}
          suppressDragLeaveHidesColumns
          loading={props.loading}
        />
      </div>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 1}}
        open={summaryOpen}
      >
        <Grid2 container style={{justifyContent: "center", alignItems: "center"}}>
          <Paper
            square={false}
            elevation={3}
            style={{
              textAlign: "center",
              padding: "50px"
            }}
          >
            <Grid2 container size={12} style={{justifyContent: "center", alignItems: "center", paddingBottom: "50px"}}>
              <Summary
                campaign={selectedCampaign}
                paperWidth={1000}
                paperHeight={"%50"}
              />
            </Grid2>
            <Grid2 container size={12} style={{justifyContent: "center", alignItems: "center", paddingBottom: "50px"}}>
              <Paper
                square={false}
                elevation={3}
                style={{
                  width: 1000,
                  textAlign: "center",
                  padding: "50px",
                  paddingTop: "0px"
                }}
              >
                <URLViewer
                  URL={selectedCampaign.urlString}
                  style={{
                    urlFieldWidth: "100%",
                    titleMarginRight: "0px"
                  }}
                />
              </Paper>
            </Grid2>
            <Grid2 container size={12} style={{justifyContent: "center", alignItems: "center"}}>
              <Button
                color="primary"
                variant="contained"
                style={{width: "25%", height: "50px", textTransform: "none"}}
                onClick={() => setSummaryOpen(false)}
              >
                Close
              </Button>
            </Grid2>
          </Paper>
        </Grid2>
      </Backdrop>
    </>
  );
}

